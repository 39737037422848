import { Component, OnInit } from '@angular/core';
import { UtilService } from '../services/index.util.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FileService } from '../services/file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadWithTagComponent } from '../component/upload-with-tag/upload-with-tag/upload-with-tag.component';

@Component({
  selector: 'app-toolbar-options',
  templateUrl: './toolbar-options.component.html',
  styleUrls: ['./toolbar-options.component.scss']
})
export class ToolbarOptionsComponent implements OnInit {

  search_query : string;
  applyFilter = new Subject<string>();
  selectedFiles: File;
  uploadInProgress = false;
  uploadStatus = 'Pending';
  
  constructor(public utilService: UtilService, private snackBar: MatSnackBar, private matDialog: MatDialog) { 
    // search filter 
    this.applyFilter.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.utilService.toolbarOptions.search.filter(this.search_query);
      });

  }

  ngOnInit(): void {
  }

  fnUploadClick(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      tags : [],
      selectedFiles : this.selectedFiles
    },
    dialogConfig.disableClose = true;
    let dialogRef = this.matDialog.open(UploadWithTagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {          
          if(data == 'success'){}
        }
    );  
  }

}
