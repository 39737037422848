import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './main/page-not-found/page-not-found.component';

export const APP_ROUTES: Routes = [
    // {
    //   path: 'login',
    //   loadChildren: () => import('./main/login/login.module').then(m => m.LoginModule)
    // },
    {
      path: 'files',
      loadChildren: () => import('./main/file-list/file-list.module').then(m => m.FileListModule)
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'file/:id',
      loadChildren: () => import('./main/file-details/file-details.module').then(m => m.FileDetailsModule)
    },
    {
      path: 'error/:id',
      loadChildren: () => import('./main/error-status/error-status.module').then(m => m.ErrorStatusModule)
    },
    // {
    //   path: 'users',
    //   loadChildren: () => import('./main/user/user.module').then(m => m.UserModule)
    // },
    // {
    //   path: 'roles',
    //   loadChildren: () => import('./main/role/role.module').then(m => m.RoleModule)
    // },
    {
      path: 'apps',
      loadChildren: () => import('./main/application/application.module').then(m => m.AppsModule)
    },
    // {
    //   path: 'additional-remarks',
    //   loadChildren: () => import('./main/advisory/additional-remarks/additional-remarks.module').then(m => m.AdditionalRemarksModule)
    // },
    // {
    //   path: 'prepaid-charges',
    //   loadChildren: () => import('./main/advisory/prepaid-charges/prepaid-charges.module').then(m => m.PrepaidChargesModule)
    // },
    {
      path: 'templates',
      loadChildren: () => import('./main/templates/templates.module').then(m => m.TemplatesModule)
    },
    {
      path: 'integrations',
      loadChildren: () => import('./main/integrations/integrations.module').then(m => m.IntegrationsModule)
    },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
    // { path: '**', component: PageNotFoundComponent }
];