<div fxLayout="row wrap" fxFlex="100">
  <div fxFlex="100">
    <app-toolbar *ngIf="utilService.layout.showToolbar"></app-toolbar>
    <!-- <app-navbar *ngIf="utilService.layout.showNavbar"></app-navbar>  -->
    <div [ngClass]="{'mt-80' : utilService.layout.showToolbar}">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

