
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

/* Angular material */
import { AngularMaterialModule } from './../../../../angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UploadWithTagComponent } from './upload-with-tag.component';

const routes = [];

@NgModule({
  declarations: [
    UploadWithTagComponent
    ],
  imports: [
    AngularMaterialModule,
    FlexLayoutModule,
    FormsModule,
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule
  ],
  exports     : [
    UploadWithTagComponent
    
  ],
  providers: [
  ]
})
export class UploadWithTagModule { }
