import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { url } from './../../../global.variables';
import { Router } from '@angular/router';


import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressSpinnerComponent } from './../../layout/progress-spinner/progress-spinner.component';
import { SupportedFile, Vendor, Subvendor, InclusionList, fieFilterObj, IntegrationInterface, VendorProfileInterface } from './../interfaces';

import { Observable } from 'rxjs';

@Injectable()
export class UtilService {

  domain = url.toString();

  layout = {
    showNavbar: false,
    showToolbar: false
  };
  fileDetails : any = null;
  SupportedTemplates : SupportedFile[] = [];
  selectedSupportedFile : SupportedFile;
  userData : any;
  vendorList : Vendor[] = [];
  subvendorList: Subvendor[] = [];
  isNextDisabled : boolean;
  isLoading : boolean = true;
  overlayRef : any;
  currencyList : any[] = [];
  loadList : any[] = [];
  basisList : any[] = [];
  response : any;
  missingLookupPort : any[] = [];
  missingLookupCharge : any[] = [];
  inclusionList : InclusionList[] = [];
  skip_validation : false;
  amountList : any[] = [];
  currencyData : any[] = [];
  remarkData : any[] = [];
  userList : any[] = [];
  uploadUserList : any[] = [];
  availableSheets : any = {};
  fileFilter : fieFilterObj = {
    "limit": 10,
    "offset": 0,
    "search_query": "",
    "vendors": [],
    "sub_vendors": [],
    "assigned_to" : [],
    "file_version": "",
    "file_name" : "",
    "uploaded_by": [],
    "from": null,
    "to": null,
    "processing_status": [],
    "processing_env": [],
    "status" : [],
    "tags" : []
  };
  dateOption : string = '';
  csvObj : any[] =[];
  isDisplayJson : boolean = false;
  agentList : any[] = [];
  activeStepper :  number;
  title : string;
  toolbarOptions : any;
  filesUploadResponse : any;
  uploadInProgress : boolean;

  
  userRole : any = {
    isProcessingUser : false,
    isPeocessingAdmin : false,
    isAutomationUser : false
  }

  loader = new OverlayConfig({
    hasBackdrop: true,
    // backdropClass: 'cdk-overlay-transparent-backdrop',
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
  });

  integrationList: IntegrationInterface[] = [];
  vendorProfileList: VendorProfileInterface[] = [];

  constructor(public http: HttpClient, private router: Router, private overlay: Overlay) {
  }

  setLayout(user_authenticated){
    this.layout.showToolbar = user_authenticated;
    this.layout.showNavbar = user_authenticated;
    this.toolbarOptions = {
      'buttons': [],
      'search': {
        show: false,
        filter: null
      }
    }
  }

  showLoaderOverlay() {
    this.overlayRef = this.overlay.create(this.loader);

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });

    this.overlayRef.attach(new ComponentPortal(ProgressSpinnerComponent));
    this.isLoading = true;
  }

  hideLoaderOverlay(){
    this.overlayRef.detach()
    this.isLoading = false;
    // this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach() );
  }

  public getCurrencies(){
    return this.http.get(this.domain + 'api/supported-currencies');
  }

  fncheckRequired(availableSheet, selectedSheets){
    let result = false;
    for(let i = 0; i < availableSheet.length; i++){
      if(selectedSheets.find( c => ( c[availableSheet[i]] == "" || c[availableSheet[i]] == null ) )){
        result = true;
       }
     }    
     return result;
  }

  fnResetFilter(){
    let fileFilter : fieFilterObj = {
      "limit": 10,
      "offset": 0,
      "search_query": "",
      "vendors": [],
      "sub_vendors": [],
      "assigned_to" : [],
      "file_version": "",
      "file_name" : "",
      "uploaded_by": [],
      "from": null,
      "to": null,
      "processing_status": [],
      "processing_env": [],
      "status" : [],
      "tags" : []
    };
    
    this.dateOption = "";
    this.fileFilter = JSON.parse(JSON.stringify(fileFilter)); 
  }

  public uploadFiles(data): Observable<any> {
    let url = this.domain + 'api/file-upload';
    return this.http.post<any>(url, data);
  }
}
