import { Component, ViewChild, OnInit } from '@angular/core';
import { AngularMaterialModule } from './../../angular-material.module';
import { AuthService } from './../../main/authentication/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild('sidenav') sidenav: AngularMaterialModule;
  isExpanded = false;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  logout(){
    this._authService.logout();
  }

  constructor(private _authService: AuthService) { }

  ngOnInit(): void {
  }

}
