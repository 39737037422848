<div class="upload-with-tag" fxLayout="column" fxFlex="100">

  <div fxLayoutGap="20px" class="mb-10 primary-fg font-size-20">
    <div fxLayoutAlign="start center"> Upload Files </div>
    <div fxFlex fxLayoutAlign="end center"> <mat-icon (click)="fnClosePopup('close')" class="pointer">close</mat-icon> </div>
  </div>
  <mat-divider></mat-divider>

  <mat-dialog-content fxLayout="column" fxFlex="100">
    <div fxLayout="column" fxFlex="100" >
      <div class="pt-20">
        <input type="file" multiple (change)="onFileSelect($event)" required>
      </div>
      <div *ngIf="errorMessage" class="error-fg font-size-12"> {{errorMessage}} </div>

      <div class="pv-10">
        <mat-form-field class="full-width">
            <mat-label class="primary-fg">Tags</mat-label>
            <mat-chip-list #tagList aria-label="Tags" multiple >
              <mat-chip *ngFor="let tag of data.tags" [value]="tag" 
                [selected]="selectable" [removable]="removable" (removed)="removeKeyword($event, data.tags, null, tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="New tag..." [matChipInputFor]="tagList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addKeywordFromInput($event, data.tags, null)">
            </mat-chip-list>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>
  <mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button mat-raised-button color="primary" matBadge="8" matBadgePosition="before" matBadgeColor="accent" [disabled]="btnDisable" (click)="fnOnSubmit()">Upload</button>
  </mat-dialog-actions>

</div>
