
<mat-sidenav #sidenav class="example-sidenav" fixedInViewport mode="side" opened="true" >
    <button mat-icon-button (click)="isExpanded = !isExpanded" class="expand-btn"><mat-icon>menu</mat-icon></button>
    
    <mat-nav-list>
    <mat-list-item >
    </mat-list-item>
    </mat-nav-list>

    <mat-nav-list (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" >
        <mat-list-item [routerLink]="['/dashboard']">
            <span class="full-width w-170" *ngIf="isExpanded || isShowing">Dashboard</span>
            <mat-icon mat-list-icon>dashboard</mat-icon>
        </mat-list-item>
    </mat-nav-list>
    <mat-nav-list (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" >
        <mat-list-item [routerLink]="['/files']">
            <span class="full-width w-170" *ngIf="isExpanded || isShowing">Files</span>
            <mat-icon mat-list-icon>file_copy</mat-icon>
        </mat-list-item>
    </mat-nav-list>

    <button mat-icon-button (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" (click)="showSubmenu = !showSubmenu; logout()" class="expand-btn">
        <mat-icon>power_settings_new</mat-icon>
        <span class="full-width w-170 font-size-16 pl-10" *ngIf="isExpanded || isShowing">Logout</span>
    </button>
</mat-sidenav>