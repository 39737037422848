import { Component, OnInit, Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { UtilService } from 'src/app/main/services/index.util.service';

@Component({
  selector: 'app-upload-with-tag',
  templateUrl: './upload-with-tag.component.html',
  styleUrls: ['./upload-with-tag.component.scss']
})
export class UploadWithTagComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable : boolean = true;
  removable : boolean = true;
  uploadStatus = 'Pending';
  errorMessage : string = '';
  btnDisable : boolean = false;
  filesUploadResponse : any[];

  constructor(public dialogRef: MatDialogRef<UploadWithTagComponent> , @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private router: Router, public utilService: UtilService) { }

  ngOnInit() {
  }

  // tags add text in matChip
  addKeywordFromInput(event: MatChipInputEvent, tags, element) {
    if (event.value) {
      tags.push(event.value);
      event.input.value = '';
    }
  }

  // tags matChip remove function
  removeKeyword(event: MatChipInputEvent, tags, element, keyword: string) {
    const index = tags.indexOf(keyword);

    if (index >= 0) {
      tags.splice(index, 1);
    }
  }

  // file input selection function
  onFileSelect(event) {
    this.data.selectedFiles = event.target.files;
    this.errorMessage = '';
  }

  // dialog modal close
  fnClosePopup(message){
    this.dialogRef.close(message);
  }

  // file and tag submit function
  fnOnSubmit(){

    if(!this.data.selectedFiles){
      this.errorMessage = 'Please choose files.'
    }
    if (parseInt(this.data.selectedFiles.length) > 20){
      this.errorMessage = 'You are only allowed to upload a maximum of 20 files.';
      return;
    }
    if (this.data.selectedFiles.length > 0) {
      for (let i = 0; i <= this.data.selectedFiles.length - 1; i++) {

          let fSize = this.data.selectedFiles.item(i).size;
          // check size of the file.
          if(fSize > 5000000){
            this.errorMessage = 'File too Big, please select a file less than 5MB.';
              return;
          }
      }
    }

    this.utilService.uploadInProgress = true;
    this.btnDisable = true;
    let data = new FormData();

    for (let i = 0; i < this.data.selectedFiles.length; i++) {
      // this.data.selectedFiles[i]["tags"] = this.data.tags;
      data.append('files', this.data.selectedFiles[i])
    }
    data.append('tags', JSON.stringify(this.data.tags))

    this.utilService.uploadFiles(data).subscribe(data =>{
      this.filesUploadResponse = data;
      this.utilService.uploadInProgress = false;
      this.btnDisable = false;
      this.uploadStatus = 'Success';
      this.snackBar.open('Files Uploaded Successfully!', '', { duration: 2000 });
      this.fnClosePopup(this.uploadStatus);
    }, error => {
      this.utilService.uploadInProgress = false;
      this.btnDisable = false;
      this.uploadStatus = 'Error';
      this.snackBar.open('Something went wrong. Try Again!', '', { duration: 2000 });
    });
  }

}
