<div fxLayout="row wrap" fxFlex=100 class="toolbar-container">
  <div id="menu" fxFlex="100" class="white-bg" fxLayoutAlign="start center">
    <div fxLayoutAlign="end center" class="w-140">
      <div class="drop">
        <a href="#"> <img src="../../assets/icon/logo.png" width="100" height="auto">
          <!-- <mat-icon class="pointer font-size-30" #toggleButton>menu</mat-icon> -->
        </a>

        <div class="dropdown_2columns">
          <div class="font-size-18 pv-10 ph-20">
            <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center">
              <button mat-stroked-button *ngFor="let subApp of navigationList" class="app" [routerLink]="['/'+subApp.name]"><mat-icon class="mr-5">{{subApp.icon}}</mat-icon>{{subApp.name | titlecase}}</button>
              <!-- <div fxFlex class="mb-20" *ngFor="let item of navigationList" >
                <div *ngIf="item.sub_applications.length"> -->
                  <!-- <div class="app text-uppercase"> {{item.name}} </div> -->
                  <!-- <mat-divider></mat-divider> -->
                  <!-- <div fxLayout="row wrap"> -->
                    <!-- <mat-nav-list class="list-horizontal" *ngFor="let subApp of item.sub_applications">
                      <mat-list-item [routerLink]="['/'+subApp.name]" [routerLinkActive]="['active']" class="sub-app">
                         
                      </mat-list-item>
                    </mat-nav-list> -->
                  <!-- </div> -->
                <!-- </div> -->
              <!-- </div> -->

              <!-- <div fxFlex class="mb-20">
                <div class="app"> BRIDGE </div>
                <mat-divider></mat-divider>
                <div fxLayout="row wrap">
                  <mat-nav-list class="list-horizontal">
                    <mat-list-item [routerLink]="['/users']" [routerLinkActive]="['active']" class="sub-app">
                      <span class="full-width w-170">Users</span> <mat-icon mat-list-icon>people</mat-icon>
                    </mat-list-item>
                  </mat-nav-list>

                  <mat-nav-list class="list-horizontal">
                    <mat-list-item [routerLink]="['/roles']" [routerLinkActive]="['active']" class="sub-app">
                      <span class="full-width w-170">Roles</span> <mat-icon mat-list-icon>recent_actors</mat-icon>
                    </mat-list-item>
                  </mat-nav-list>

                  <mat-nav-list class="list-horizontal">
                    <mat-list-item [routerLink]="['/apps']" [routerLinkActive]="['active']" class="sub-app">
                      <span class="full-width w-170">Apps</span> <mat-icon mat-list-icon>apps</mat-icon>
                    </mat-list-item>
                  </mat-nav-list>
                </div>
              </div>

              <div fxFlex class="mb-20">
                <div class="app"> COMPASS </div>
                <mat-divider></mat-divider>
                <div fxLayout="row wrap">
                  <mat-nav-list class="list-horizontal">
                    <mat-list-item [routerLink]="['/prepaid-charges']" class="sub-app">
                      <span class="full-width w-170">Prepaid Charges</span> <mat-icon mat-list-icon>import_contacts</mat-icon>
                    </mat-list-item>

                    <mat-list-item [routerLink]="['/additional-remarks']" class="sub-app">
                      <span class="full-width w-170">Additional Remarks</span> <mat-icon mat-list-icon>rate_review</mat-icon>
                    </mat-list-item>
                  </mat-nav-list>
                </div>
              </div>

              <div fxFlex class="mb-20">
                <div class="app"> TEMPLATES </div>
                <mat-divider></mat-divider>
                <div fxLayout="row wrap">
                  <mat-nav-list class="list-horizontal">
                    <mat-list-item [routerLink]="['/supported-files']" class="sub-app">
                      <span class="full-width w-170">Supported Files</span> <mat-icon mat-list-icon>description</mat-icon>
                    </mat-list-item>
                  </mat-nav-list>
                </div>
              </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-title" fxLayoutAlign="center center">{{utilService.title}}</div>

    <div fxFlex>
      <app-toolbar-options></app-toolbar-options>
    </div>

    <div class="menu_right" fxLayoutAlign="center center">
      <!-- <button mat-icon-button (click)="logout()" class="primary-fg">
          <mat-icon>power_settings_new</mat-icon>
        </button>
      <div class="menu_right font-size-16" ><div class="mt-10 primary-fg"> {{utilService.userData.name}} </div> </div>
     -->
      <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="Example icon-button with a menu" class="more_btn">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <mat-progress-bar *ngIf="utilService.uploadInProgress" mode="indeterminate"></mat-progress-bar>
</div>