// export const url = 'http://192.168.201.148:8090/';
export const site = 'http://localhost:4200/';
// export const site = 'http://devtrack.freightbro.com/';

const localurl = 'http://127.0.0.1:8075/';
const devurl = 'https://mnp8bibvt6.execute-api.ap-south-1.amazonaws.com/dev/';
const stagingurl = 'https://251rsq0ghc.execute-api.ap-south-1.amazonaws.com/staging/'
// const produrl = 'https://83au7aybhi.execute-api.ap-south-1.amazonaws.com/prod/';
const produrl = 'https://gkfvezkgu2.execute-api.ap-south-1.amazonaws.com/prod/';

export const url = stagingurl;
// export const url = 'https://mnp8bibvt6.execute-api.ap-south-1.amazonaws.com/dev/';
export const localHostName = 'fwda.demoship.freightbro.com';