// import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './main/page-not-found/page-not-found.component';

/* Angular material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './main/authentication/token.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import { AuthService } from './main/authentication/auth.service';
import { UtilService } from './main/services/index.util.service';

import { ProgressSpinnerComponent } from './layout/progress-spinner/progress-spinner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToolbarOptionsComponent } from './main/toolbar-options/toolbar-options.component';
import { NoDataComponent } from './no-data/no-data.component';
import { UploadWithTagModule } from './main/component/upload-with-tag/upload-with-tag/upload-with-tag.module';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://auth.freightbro.in/auth',
        realm: 'freightbro_internal',
        clientId: 'dps-staging',
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: [
        '/assets',
        '/clients/public']
    });
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    NavbarComponent,
    ToolbarComponent,
    ProgressSpinnerComponent,
    ToolbarOptionsComponent,
    NoDataComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    OverlayModule,
    RouterModule.forRoot(APP_ROUTES),
    BrowserModule,
    KeycloakAngularModule,
    UploadWithTagModule
  ],
  providers: [
    AuthService,
    UtilService,
    // {
    // provide: HTTP_INTERCEPTORS,
    // useClass: TokenInterceptor,
    // multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
  ],
  entryComponents: [ProgressSpinnerComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
