import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from './../../main/authentication/auth.service';
import { UtilService } from 'src/app/main/services/index.util.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleButton', { read: ElementRef }) toggleButton: ElementRef;
  @ViewChild('menu', { read: ElementRef }) menu: ElementRef;

  navigationList: any = null;

  constructor(private _authService: AuthService, public utilService: UtilService, private renderer: Renderer2) {

    this.renderer.listen('window', 'click', (e) => {
      if (this.menu && e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
        this.isMenuOpen = false;
      }
    });

  }

  isMenuOpen = false;

  ngOnInit() {
    this.navigationList = [
      {
        name: "dashboard",
        icon: "dashboard"
      },
      {
        name: "files",
        icon: "insert_drive_file"
      },
      {
        name: "integrations",
        icon: "device_hub"
      },
      {
        name: "templates",
        icon: "description"
      }
    ];
  }

  ngAfterViewInit() {
  }

  // fnSetNavigation(){

  // this.utilService.userData.show_apps.forEach(element => {
  //   if(element.name == 'dashboard' || element.name == 'files'){
  //     this.navigationList.general.push(element);
  //   }else if(element.name == 'users' || element.name == 'roles' || element.name == 'apps'){
  //     this.navigationList.bridge.push(element);
  //   }else if(element.name == 'prepaid-charges' || element.name == 'additional-remarks'){
  //     this.navigationList.compass.push(element);
  //   }else if(element.name == 'supported-files' || element.name == 'sub-vendor'){
  //     this.navigationList.templates.push(element);
  //   }
  // });
  // }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  logout() {
    this._authService.logout();
  }

  // getFlex() {
  //   var length = (this.utilService.title) ? this.utilService.title.length: 0;
  //   if ( length < 10){
  //     return length + 4;
  //   }
  //   else if (length > 10 && length < 20){
  //     return length;
  //   }
  //   else{
  //     return 30;
  //   }
  // }

}
