<!-- <p>toolbar-options works! {{utilService.toolbarOption}} </p> -->
<div fxLayout="column" flex="100" class="pl-10 pr-10">
    
  <div fxLayout="row" fxLayoutAlign="end end" class="mr-20">
    <button (click)="fnUploadClick()" mat-button class="filter-icon  white-bg primary-fg primary-border">Upload Files</button>
    <!-- <input style="display: none" type="file" multiple (change)="onFileSelect($event)" #hiddenFileInput> -->

    <mat-form-field floatLabel="never" class="ph-20 w-200 h-40" *ngIf="utilService.toolbarOptions.search.show">
      <mat-label>
        <span> <mat-icon mat-list-icon>search</mat-icon> </span> 
        Search
      </mat-label>
      <input matInput [(ngModel)]="search_query" type="text" name="searchText" (ngModelChange)="applyFilter.next($event)" placeholder="">
    </mat-form-field>
    <div *ngFor="let btn of utilService.toolbarOptions.buttons" class="mh-10">
      <button mat-button class="filter-icon  white-bg primary-fg primary-border" (click)="btn.onClick()" *ngIf="btn.icon">
          <mat-icon>{{btn.icon}}</mat-icon>
      </button>
      <button mat-button (click)="btn.onClick()" class="toolbar-btn" *ngIf="!btn.icon" >{{btn.label}}</button>
    </div>
  </div>
  <!-- <div fxFlex >
    
  </div> -->
</div>